/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Link, useParams } from 'react-router-dom';
import { GetBlockList } from '@src/utils/apis';
import { BlockItem } from '@src/models';
import { formatUnixWithoutYear } from '@src/utils/tools';
import { Bubble } from 'tea-component';
import right from '@imgs/right.png';
import TWEEN from '@tweenjs/tween.js';
let timeManage: any;
export default function NewBlocks() {
  const { chainId } = useParams();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [listLeft, setListLeft] = useState<number>(0);
  const [blocks, setBlocks] = useState<Array<BlockItem | null>>([null, null, null, null]);
  // 触发开始递归刷新数据
  const triggerUpdate = useCallback(() => {
    clearTimeout(timeManage);
    timeManage = setTimeout(() => {
      setRefreshCount(refreshCount + 1);
    }, 3000);
  }, [refreshCount]);
  /**
   * @param 更新的列表数据
   * 开始
   */
  const moveList = useCallback(
    (list: Array<BlockItem | null>) => {
      if (list.length) {
        // 记录久数组第一条数据对于新数组中的位置
        let number: number | null = null;
        let newBlocks = list;
        for (let i = 0; i < 4; i++) {
          const block = list[i];
          if (block?.BlockHeight === blocks[0]?.BlockHeight) {
            number = i;
            break;
          }
        }
        if (number !== 0) {
          if (number === null) {
            number = newBlocks.length;
            newBlocks = newBlocks.concat(blocks);
          } else {
            newBlocks = newBlocks.slice(0, number).concat(blocks);
          }
          const left = number * -332;
          setBlocks(newBlocks);
          setListLeft(left);
          new TWEEN.Tween({ left })
            .to({ left: 0 }, 1000)
            .duration(1500)
            .easing(TWEEN.Easing.Cubic.Out)
            .onUpdate((info) => {
              setListLeft(info.left);
              if (info.left === 0) {
                setBlocks(newBlocks.slice(0, 4));
              }
            })
            .start();
        }
      }
      triggerUpdate();
    },
    [blocks, triggerUpdate],
  );
  const updateList = useCallback(() => {
    GetBlockList({
      ChainId: chainId,
      Limit: 4,
    }).then((res) => {
      if (res.GroupList) {
        moveList(res.GroupList);
      }
    });
  }, [chainId, moveList]);
  const stopMove = useCallback(() => {
    clearTimeout(timeManage);
  }, []);
  useEffect(() => {
    updateList();
    return () => {
      stopMove();
    };
  }, [refreshCount]);
  return (
    <>
      <Between
        title="最新区块"
        content={
          <Link className={style.block_more} to={`/${chainId}/block`}>
            更多
            <img src={right} />
          </Link>
        }
      />
      <div className={style.block_steps}>
        <div
          className={style.block_steps_list}
          style={{ left: listLeft }}
          onMouseEnter={stopMove}
          onMouseLeave={triggerUpdate}
        >
          {blocks?.map((block, index) =>
            block ? (
              <Link
                to={`/${chainId}/block/${block.BlockHeight}`}
                key={block.BlockHeight}
                className={style.block_step_i}
              >
                <div className={style.block_line}>
                  <div className={style.block_title}>#{block.BlockHeight}</div>
                  <div className={style.block_time}>{formatUnixWithoutYear(block.Timestamp)}</div>
                </div>
                <div className={style.block_line}>
                  <div className={style.block_subtitle}>{block.TxCount} Txns</div>
                  <div className={`${style.block_path} single_line`}>
                    <Bubble content={block.ProposalNodeId}>{block.ProposalNodeId}</Bubble>
                  </div>
                </div>
              </Link>
            ) : (
              <div
                key={index}
                className={`${index !== 0 ? style.block_step_triangle : ''} ${style.block_step_i}`}
              ></div>
            ),
          )}
        </div>
      </div>
    </>
  );
}
